import React from 'react';
import PropTypes from 'prop-types';
import Header from 'cccisd-header';
import style from './style.css';

const Appdefs = window.cccisd.appDefs;
const Env = window.cccisd.env;
const Fortress = window.cccisd.fortress;

const NavBar = ({ className, noLogoLink }) => {
    let primaryNavLinks = [];
    try {
        primaryNavLinks = Appdefs.navs.find(n => n.handle === 'primaryNav').nav;
    } catch (e) {
        // meh, leave it empty
    }

    return (
        <Header
            containerClassName={className}
            logoClassName={style.logo}
            primaryNavLinks={primaryNavLinks}
            showAlertAction={false}
            showAdminAction={Fortress.isSuperUser()}
            // learner role has a Nav tab that says "Course" so they can go back and forth
            // between course and WP page
            showHomeAction={Fortress.user.acting.role.handle !== 'learner'}
            logoLink={noLogoLink ? '' : Env.wpUrl || 'https://smallstepsforbigchanges.ca'}
        />
    );
};

NavBar.defaultProps = {
    className: 'container',
    noLogoLink: false,
};

NavBar.propTypes = {
    className: PropTypes.string,
    noLogoLink: PropTypes.bool,
};

export default NavBar;
