import React from 'react';
import style from './style.css';

const UBCFooter = () => {
    return (
        <div className={style.footerWrapper}>
            <div className={style.footerInner}>
                Website produced by{' '}
                <a href="https://www.3cisd.com/" rel="noopener noreferrer" target="_blank">
                    3C Institute
                </a>
                . All Rights Reserved |{' '}
                <a
                    href="https://www.3cisd.com/terms-of-use"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    Terms of Use
                </a>{' '}
                |{' '}
                <a
                    href="https://www.3cisd.com/privacy-policy"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    Privacy Policy
                </a>{' '}
                |
            </div>
        </div>
    );
};

export default UBCFooter;
