import React, { useEffect, useState } from 'react';
import _get from 'lodash/get';
import { client as apollo } from 'cccisd-apollo';
import { DeploymentPlayer } from 'cccisd-laravel-assignment';
import Loader from 'cccisd-loader';
import assignmentPlanQuery from './assignmentPlan.graphql';
import style from './style.css';

const CurrentAssignmentPlan = () => {
    const [loading, setLoading] = useState(true);
    const [pawn, setPawn] = useState(null);
    const [session, setSession] = useState(null);

    /* /////////////////////////////////////////////////////////////////////////
    // LIFECYCLE FUNCTIONS ////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////// */

    useEffect(() => {
        async function loadData() {
            const result = await apollo.query({
                query: assignmentPlanQuery,
                fetchPolicy: 'network-only',
            });

            setPawn(_get(result, `data.roles.learner.pawn`, null));
            setSession(
                _get(
                    result,
                    `data.roles.learner.parentGroup.class.selectedAssignmentPlan.currentSession`,
                    null
                )
            );
            setLoading(false);
        }

        loadData();
    }, []);

    /* /////////////////////////////////////////////////////////////////////////
    // RENDER /////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////// */

    if (loading) {
        return <Loader loading />;
    }

    if (pawn === null || session === null) {
        return (
            <div>
                <h2 className={style.errorHeader}>Error</h2>
                <p>Something went wrong...</p>
            </div>
        );
    }

    if (session.deployment.deploymentId === null) {
        return (
            <div>
                <h2 className={style.errorHeader}>No Current Assignment</h2>
                <p>You do not have any assignments to do right now.</p>
                <p className={style.errorMsg}>
                    If you believe you have received this message in error, please email{' '}
                    <a href="mailto:support@3cisd.com">3C Support</a>.
                </p>
            </div>
        );
    }

    return (
        <DeploymentPlayer
            deploymentId={session.deployment.deploymentId}
            pawnId={pawn.pawnId}
            pawnHash={pawn.pawnHash}
            assignmentOptions={session.settings.options}
            disableLayout
        />
    );
};

export default CurrentAssignmentPlan;
